<template>
  <div>
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            ประชาสัมพันธ์
          </h1>
        </b-col>
      </b-row>

      <div class="bg-white p-3" v-if="$isLoading">
        <b-row class="pl-1">
          <b-col cols="6">
            <div class="panel d-flex align-items-md-center">
              <b-form-checkbox
                size="lg"
                id="sameLang"
                class="mt-0 mt-sm-3"
                @change="useSameLanguage"
                v-model="form.isSameLanguage"
                >ใช้เหมือนกันทุกภาษา
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col cols="6" class="text-right">
            <div class="panel">
              <b-button
                type="button"
                class="btn btn-language"
                v-for="(language, index) in languageList"
                v-bind:key="index"
                v-bind:class="[languageActive == language.id ? 'active' : '']"
                @click="changeLanguage(language.id, index)"
                :disabled="form.isSameLanguage ? true : false"
              >
                <span class="text-uppercase">{{ language.nation }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col md="6">
            <InputText
              textFloat="ชื่อแบนเนอร์"
              placeholder="ชื่อแบนเนอร์"
              type="text"
              name="name"
              v-model="form.banner.name"
              isRequired
              @onKeyup="setAlttagByLang(form.banner.name, languageActive-1)"
              :isValidate="$v.form.banner.name.$error"
              :v="$v.form.banner.name"
            />
          </b-col>
          <b-col md="6">
            <div
              v-for="(item, index) in form.bannerTranslationList"
              v-bind:key="index"
            >
              <div
                v-bind:class="[
                  languageActive == item.languageId ? '' : 'd-none',
                ]"
              >
                <InputText
                  textFloat="Alt tag"
                  placeholder="Alt tag"
                  type="text"
                  name="name"
                  :img="imageLogoLang"
                  v-model="item.altTag"
                  @onKeyup="setAlttagByLang(item.altTag, index)"
                  isRequired
                  :isValidate="$v.form.bannerTranslationList.$each.$iter[index].altTag.$error"
                  :v="$v.form.bannerTranslationList.$each.$iter[index].altTag"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <DateTimePicker
              textFloat="วันเริ่มต้นแสดง"
              type="datetime"
              isTime
              placeholder="กรุณาเลือกวันที่และเวลา"
              name="form.banner.startDate"
              v-model="form.banner.startDate"
              isRequired
              v-bind:isValidate="$v.form.banner.startDate.$error"
              :v="$v.form.banner.startDate"
            />
          </b-col>
          <b-col md="6">
            <DateTimePicker
              textFloat="วันสิ้นสุดแสดง"
              type="datetime"
              isTime
              placeholder="กรุณาเลือกวันที่และเวลา"
              name="form.banner.endDate"
              v-model="form.banner.endDate"
              isRequired
              v-bind:isValidate="$v.form.banner.endDate.$error"
              :v="$v.form.banner.endDate"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <UploadFile
              textFloat="ภาพแบนเนอร์สำหรับคอมพิวเตอร์"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="image"
              name="fileNameComputer"
              :fileName="fileNameComputer"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 1:1 ขนาดไม่เกิน 2 MB"
              isRequired
              v-on:onFileChange="onImageChange"
              v-on:delete="deleteImage"
              :v="$v.fileNameComputer"
              :maxSize="2000000"
            />
            <div
              class="preview-box ratio-1-1-pb"
              v-if="coverImgType == 1"
              v-bind:style="{ 'background-image': 'url(' + showPreview + ')' }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingImage"
              />
            </div>
          </b-col>
          <b-col md="6">
            <UploadFile
              textFloat="ภาพแบนเนอร์สำหรับมือถือ"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="image"
              name="fileNameMobile"
              :fileName="fileNameMobile"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 1:1 ขนาดไม่เกิน 2 MB"
              isRequired
              v-on:onFileChange="onMobileImageChange"
              v-on:delete="deleteMobileImage"
              :v="$v.fileNameMobile"
              :maxSize="2000000"
            />
            <div
              class="preview-box ratio-1-1-pb"
              v-bind:style="{
                'background-image': 'url(' + mobileShowPreview + ')',
              }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingMobileImage"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <label class="font-weight-bold main-label mb-2">
              แสดงที่ <span class="text-danger">*</span>
            </label>
            <span class="text-error ml-2" v-if="$v.form.banner.typeWebsite.$error"
              >กรุณาเลือก</span
            >
            <b-row class="mb-3 mb-sm-0">
              <b-col cols="2" class="mb-1 mb-sm-0">
                <b-form-radio
                  name="some-radios-types"
                  v-model="form.banner.typeWebsite"
                  value="0"
                  @change="(val) => form.banner.typeWebsite = val"
                  >ทั้งหมด</b-form-radio
                >
              </b-col>
              <b-col cols="4" class="mb-1 mb-sm-0">
                <b-form-radio
                  name="some-radios-types"
                  v-model="form.banner.typeWebsite"
                  value="1"
                  @change="(val) => form.banner.typeWebsite = val"
                  >ผู้ซื้อ (Seller, Member)</b-form-radio
                >
              </b-col>
              <b-col cols="4" class="mb-1 mb-sm-0">
                <b-form-radio
                  name="some-radios-types"
                  v-model="form.banner.typeWebsite"
                  value="2"
                  @change="(val) => form.banner.typeWebsite = val"
                  >ผู้ขาย (Brand)</b-form-radio
                >
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <label class="font-weight-bold main-label">
              สถานะการแสดงผล
              <span class="text-danger">*</span>
            </label>
            <div>
              <b-form-checkbox
                switch
                v-model="form.banner.display"
                class="radio-active"
                size="lg"
              >
                <span class="ml-2 main-label">{{
                  form.banner.display ? "ใช้งาน" : "ไม่ใช้งาน"
                }}</span>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col md="6">
            <b-button
              href="/notify-improvements"
              :disabled="isDisable"
              class="btn-details-set btn-cancel"
              >ย้อนกลับ</b-button
            >
          </b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              @click="checkForm"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึก
            </button>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import UploadFile from "@/components/inputs/UploadFile";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import { required, numeric, minValue } from "vuelidate/lib/validators";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import Vue from "vue";

export default {
  name: "NotifyImprovements",
  components: {
    InputText,
    UploadFile,
    DateTimePicker,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
  },
  data() {
    return {
      id: 10,
      isLoadingImage: false,
      isLoadingMobileImage: false,
      coverImgType: 1,
      showPreview: "",
      mobileShowPreview: "",
      isEdit: false,
      images: "",
      mobileImages: "",
      modalMessage: "",
      isDisable: false,
      fileNameComputer: "",
      fileNameMobile: "",
      languageList: [],
      languageActive: 1,
      imageLogoLang: "",
      form: {
        banner: {
          display: true,
          id: 10,
          imageUrl: "",
          name: "",
          typeWebsite: -1,
          startDate: null,
          endDate: null,
          mainLanguageId: 1,
        },
        isSameLanguage: false,
        imageBase64: "",
        mobileImageUrl: "",
        bannerTranslationList: [
          {
            languageId: 1,
            altTag: null,
          },
          {
            languageId: 2,
            altTag: null,
          },
        ],
      },
      imgTypeId: 27,
    };
  },
  validations() {
    return {
      fileNameComputer: { required },
      fileNameMobile: { required },
      form: {
        banner : {
          typeWebsite: { required, minValue: minValue(0) },
          name: { required },
          startDate: {
            required,
            // minValue: value => value > new Date().toISOString()
          },
          endDate: {
            required,
            minValue: (value) =>
              new Date(value).getTime() >
              new Date(this.form.banner.startDate || new Date()).getTime(),
          },
        },
        bannerTranslationList: {
          $each: {
            altTag: { required },
          },
        },
      },
    };
  },
  created: async function () {
    await this.getDatas();
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeSameLang: function (value) {
      this.form.isSameLanguage = value;
    },
    setTagByLang: function (value, index) {
      this.form.bannerTranslationList[index].tag = value.replace(/ /g, "-");
    },
    getDatas: async function () {
      this.$isLoading = false;

      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );
      if (languages.result == 1) {
        this.languageList = languages.detail;
        this.changeLanguage(1, 0);
      }
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/BannerClose/${this.form.banner.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail;

        //this.$v.form.$reset();

        if (this.form.banner.id > 0) {
          this.isEdit = true;
          this.showPreview = this.form.banner.imageUrl;
          this.fileNameComputer = this.form.banner.imageUrl;
          this.mobileShowPreview = this.form.banner.mobileImageUrl;
          this.fileNameMobile = this.form.banner.mobileImageUrl;
        }else{
          this.form.isSameLanguage = true;
          this.form.banner.mainLanguageId = 1;
        }
      }

      if(this.form.banner.startDate == '0001-01-01T00:00:00') this.form.banner.startDate = null;
      if(this.form.banner.endDate == '0001-01-01T00:00:00') this.form.banner.endDate = null;

      if (this.form.isSameLanguage) {
        this.imageLogoLang = "";
        this.languageActive = this.form.banner.mainLanguageId;
      } else {
        var index = this.languageList
          .map(function (x) {
            return x.id;
          })
          .indexOf(this.languageActive);
        this.imageLogoLang = this.languageList[index].imageUrl;
      }

      this.$isLoading = true;
    },
    changeLanguage(id, index) {
      if (!this.form.isSameLanguage) {
        this.languageActive = id;
        this.imageLogoLang = this.languageList[index].imageUrl;
      }
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;

        this.showPreview = this.images;
        this.form.banner.imageUrl = this.images;
        this.fileNameComputer = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        base64: img,
        type: this.imgTypeId,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    deleteImage() {
      this.form.banner.imageUrl = null;
      this.fileNameComputer = "";
      this.showPreview = null;
    },
    onMobileImageChange(img) {
      this.isLoadingMobileImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.mobileImages = await this.saveImagetoDb(reader.result);
        this.isLoadingMobileImage = false;
        this.isDisable = false;

        this.mobileShowPreview = this.mobileImages;
        this.form.banner.mobileImageUrl = this.mobileImages;
        this.fileNameMobile = this.mobileImages;
      };
    },
    deleteMobileImage() {
      this.form.banner.mobileImageUrl = null;
      this.fileNameMobile = "";
      this.mobileShowPreview = null;
    },
    checkForm: async function () {
      if (this.form.isSameLanguage) {
        await this.useSameLanguage();
      }
      this.$v.$touch();
      if (this.$v.$error) {
        await this.checkValidateBannerTranslationList();
        return;
      }
      this.modalAlertShow = false;
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/BannerClose/save`,
        null,
        this.$headers,
        this.form
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.existId = data.detail;
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();

        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.getDatas();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    useSameLanguage: async function () {
      Vue.nextTick(() => {
        if (this.form.isSameLanguage) {
          this.imageLogoLang = "";
          this.form.banner.mainLanguageId = this.languageActive;
          let data = this.form.bannerTranslationList.filter(
            (val) => val.languageId == this.form.banner.mainLanguageId
          );

          //if (this.id == 0) {
          if (data.length == 1) {
            data = data[0];
            for (
              let index = 0;
              index < this.form.bannerTranslationList.length;
              index++
            ) {
              this.form.bannerTranslationList[index].altTag = data.altTag;
            }
          }
          //}
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);
          this.imageLogoLang = this.languageList[index].imageUrl;

          let data = this.form.bannerTranslationList.filter(
            (val) => val.languageId != this.form.banner.mainLanguageId
          );
          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              data.altTag = "";
            }
          }
        }
      });
    },
    checkValidateBannerTranslationList: async function () {
      let isError = false;
      this.languageList.forEach((element, index) => {
        if (!isError) {
          if (this.$v.form.bannerTranslationList.$each.$iter[index].$error) {
            this.languageActive =
              this.$v.form.bannerTranslationList.$model[index].languageId;

            this.imageLogoLang = this.languageList[index].imageUrl;

            isError = true;
          }
        }
      });
    },
    setAlttagByLang: function (value, index) {
      this.form.bannerTranslationList[index].altTag = value.replace(/ /g, "-");
    },
  },
};
</script>

<style scoped>
.panel {
  font-size: 17px;
  color: #16274a;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
</style>
